import React from "react"

const ErrorPage = () => {
  return (
    <div>
      <div className="flex items-center justify-center h-screen">
        <div>
          <h1 className="mb-4 text-4xl">404</h1>
          <p className="mb-16">Not found!</p>
        </div>
      </div>
    </div>
  )
}

export default ErrorPage
